<template>
    <div>
        <!-- Add Relationship -->
        <v-card flat>
            <v-card-text :key="refreshKey">
                <v-row justify="center">
                    <v-col cols="12" sm="12">
                        <v-row class="my-2">
                            <v-col cols="12" sm="12" md="4" class="py-0 my-0">
                                <v-autocomplete ref="countrySelect" hide-no-data hide-selected label="Country" dense
                                    outlined :items="countries" clearable item-text="name" item-value="id"
                                    :menu-props="{ closeOnContentClick: true }" return-object v-model="selectedCountry"
                                    @change="countryChange" :loading="loadingCountry">
                                    <template v-slot:selection="data">
                                        <v-avatar size="32" class="mr-2" v-if="data.item">
                                            <v-img v-if="data.item.iso2" contain
                                                :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"></v-img>
                                        </v-avatar>
                                        <span v-if="data.item">
                                            {{ data.item.name }}
                                        </span>
                                    </template>
                                    <template v-slot:item="data">
                                        <v-list-item-avatar>
                                            <v-img contain v-if="data.item.iso2"
                                                :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="8" class="py-0 my-0">
                                <v-text-field :disabled="!selectedCountry" outlined dense
                                    placeholder="Search for Organisation" ref="companySearch" clearable
                                    v-model="searchOrganisation"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="(!item.relatedOrganisation ||
                                (item.relatedOrganisation &&
                                    !item.relatedOrganisation.id)) &&
                                selectedCountry
                                ">
                                <v-data-table disable-pagination hide-default-footer style="cursor: pointer"
                                    :items="organisationList.data" :headers="companyHeaders" :loading="isLoading"
                                    @click:row="selectCompany">
                                    <template v-slot:[`item.address`]="{ item }">
                                        <v-row>
                                            <v-col cols="12" v-for="address in item.organisationAddresses"
                                                :key="address.id">
                                                <v-icon small class="mr-1">location_on</v-icon>
                                                <span class="text-wrap">{{
                                                    concatenateAddress(address)
                                                }}</span>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-table>
                            </v-col>

                            <v-col cols="12" class="my-0 py-0">
                                <el-row justify="center" type="flex" v-if="(!item.relatedOrganisation ||
                                    (item.relatedOrganisation &&
                                        !item.relatedOrganisation.id)) &&
                                    selectedCountry &&
                                    organisationList.total
                                    ">
                                    <el-pagination style="color: var(--v-primaryText-base)"
                                        :current-page.sync="organisationPage" :page-size.sync="organisationParams.limit"
                                        layout="prev, pager, next" :total="organisationList.total">
                                    </el-pagination>
                                </el-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="item && item.relatedOrganisation && item.relatedOrganisation.id
                        " cols="12" class="my-0 py-0">
                        <v-row class="my-0 py-0">
                            <v-col cols="12" sm="12" md="4" class="my-0 py-0">
                                <v-list dense>
                                    <v-list-item>
                                        <v-list-item-avatar v-if="item.relatedOrganisation.logo">
                                            <v-img contain :src="item.relatedOrganisation.logo"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.relatedOrganisation.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item style="height: 20px" v-if="item.relatedOrganisation.registrationNumber">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.relatedOrganisation.registrationNumber }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px">
                                                Registration Number
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item style="height: 20px" v-if="item.relatedOrganisation.vatNumber">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.relatedOrganisation.vatNumber }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px">
                                                VAT Number
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" class="my-0 py-0">
                                <v-list>
                                    <v-subheader>Listed Addresses
                                        <v-btn icon color="primary" @click="addAddress()">
                                            <v-icon>add_circle_outline</v-icon>
                                        </v-btn>
                                    </v-subheader>
                                    <v-list-item v-for="(address, index) in item.relatedOrganisation
                                        .organisationAddresses" :key="index" @click="editAddress(address, index)">
                                        <v-list-item-action>
                                            <v-icon>location_on</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-subtitle class="text-wrap">{{
                                                concatenateAddress(address)
                                            }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-icon>
                                            <v-icon style="margin: 5px" v-if="address.isHQ"
                                                color="success">high_quality</v-icon>
                                            <v-icon style="margin: 5px" v-if="address.isDeliveryAddress"
                                                color="success">local_shipping</v-icon>
                                            <v-icon style="margin: 5px" v-if="address.isDocsAddress"
                                                color="success">description</v-icon>
                                        </v-list-item-icon>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <!-- <v-col cols="12" sm="12" md="4" class="my-0 py-0">
                  <v-list>
                    <v-subheader>Shared Contacts <v-btn icon color="primary" @click="(userItem = {}), (userDialog = true)"><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                    <v-list-item
                      v-for="(address, index) in item.relatedOrganisation
                        .organisationAddresses"
                      :key="index"
                    >
                      <v-list-item-action>
                        <v-icon>location_on</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-wrap">{{
                          concatenateAddress(address)
                        }}</v-list-item-subtitle>
                      </v-list-item-content></v-list-item
                    >
                  </v-list>
                </v-col> -->
                            <v-col cols="12" class="text-center my-0 py-0" v-if="!hideHeader">
                                <v-row align="center">
                                    <v-col cols="12" class="text-left" sm="12" md="3">
                                        <h4>Contact Type</h4>
                                    </v-col>
                                    <v-col cols="12" class="text-center" sm="12" md="9">
                                        <v-chip-group column multiple class="text-center">
                                            <v-chip outlined @click="item.isCustomer = !item.isCustomer"
                                                :color="item.isCustomer ? 'green' : 'grey'">
                                                <v-icon v-if="item.isCustomer" color="green" class="mr-1">check</v-icon>
                                                Customer
                                            </v-chip>
                                            <v-chip outlined @click="item.isSupplier = !item.isSupplier"
                                                :color="item.isSupplier ? 'green' : 'grey'">
                                                <v-icon v-if="item.isSupplier" color="green" class="mr-1">check</v-icon>
                                                Supplier
                                            </v-chip>
                                        </v-chip-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="text-center my-0 py-0" v-if="!hideHeader">
                                <v-row align="center">
                                    <v-col cols="12" class="text-left" sm="12" md="3">
                                        <h4>Relationship Network Functions</h4>
                                    </v-col>
                                    <v-col cols="12" class="text-center" sm="12" md="9">
                                        <v-chip-group column multiple class="text-center">
                                            <v-chip outlined @click="item.producer = !item.producer"
                                                :color="item.producer ? 'green' : 'grey'">
                                                <v-icon v-if="item.producer" color="green" class="mr-1">check</v-icon>
                                                Producer
                                            </v-chip>
                                            <v-chip outlined @click="item.coldstore = !item.coldstore"
                                                :color="item.coldstore ? 'green' : 'grey'">
                                                <v-icon v-if="item.coldstore" color="green" class="mr-1">check</v-icon>
                                                Coldstore
                                            </v-chip>
                                            <v-chip outlined @click="item.consignee = !item.consignee"
                                                :color="item.consignee ? 'green' : 'grey'">
                                                <v-icon v-if="item.consignee" color="green" class="mr-1">check</v-icon>
                                                Consignee
                                            </v-chip>
                                            <v-chip outlined @click="item.shipper = !item.shipper"
                                                :color="item.shipper ? 'green' : 'grey'">
                                                <v-icon v-if="item.shipper" color="green" class="mr-1">check</v-icon>
                                                Shipper
                                            </v-chip>
                                            <v-chip outlined @click="item.forwarder = !item.forwarder"
                                                :color="item.forwarder ? 'green' : 'grey'">
                                                <v-icon v-if="item.forwarder" color="green" class="mr-1">check</v-icon>
                                                Forwarder
                                            </v-chip>
                                            <v-chip outlined @click="item.notifyParty = !item.notifyParty"
                                                :color="item.notifyParty ? 'green' : 'grey'">
                                                <v-icon v-if="item.notifyParty" color="green" class="mr-1">check</v-icon>
                                                Notify Party
                                            </v-chip>
                                            <v-chip outlined @click="item.buyer = !item.buyer"
                                                :color="item.buyer ? 'green' : 'grey'">
                                                <v-icon v-if="item.buyer" color="green" class="mr-1">check</v-icon>
                                                Buyer
                                            </v-chip>
                                            <v-chip outlined @click="item.transporter = !item.transporter"
                                                :color="item.transporter ? 'green' : 'grey'">
                                                <v-icon v-if="item.transporter" color="green" class="mr-1">check</v-icon>
                                                Transporter
                                            </v-chip>
                                            <v-chip outlined @click="item.shippingLine = !item.shippingLine"
                                                :color="item.shippingLine ? 'green' : 'grey'">
                                                <v-icon v-if="item.shippingLine" color="green" class="mr-1">check</v-icon>
                                                Carrier
                                            </v-chip>
                                            <v-chip outlined @click="item.pluginProvider = !item.pluginProvider"
                                                :color="item.pluginProvider ? 'green' : 'grey'">
                                                <v-icon v-if="item.pluginProvider" color="green" class="mr-1">check</v-icon>
                                                Plugin Provider
                                            </v-chip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-chip v-on="on" outlined @click="item.vasProvider = !item.vasProvider"
                                                        :color="item.vasProvider ? 'green' : 'grey'">
                                                        <v-icon v-if="item.vasProvider" color="green"
                                                            class="mr-1">check</v-icon>
                                                        VAS Provider
                                                    </v-chip>
                                                </template>
                                                <span>Value Added Service Provider</span>
                                            </v-tooltip>

                                        </v-chip-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="text-center my-0 py-0" v-else-if="item.relatedOrganisation">
                                <v-row align="center">
                                    <v-col cols="12" class="text-left" sm="12" md="3">
                                        <h4>Network Functions</h4>
                                    </v-col>
                                    <v-col cols="12" class="text-center" sm="12" md="9">
                                        <v-chip-group column multiple class="text-center" :key="relationshipKey">
                                            <v-chip outlined @click="
                                            (item.relatedOrganisation.producer =
                                                !item.relatedOrganisation.producer),
                                                relationshipKey++
                                                " :color="item.relatedOrganisation.producer ? 'green' : 'grey'
        ">
                                                <v-icon v-if="item.relatedOrganisation.producer" color="green"
                                                    class="mr-1">check</v-icon>
                                                Producer
                                            </v-chip>
                                            <v-chip outlined @click="
                                            (item.relatedOrganisation.coldstore =
                                                !item.relatedOrganisation.coldstore),
                                                relationshipKey++
                                                " :color="item.relatedOrganisation.coldstore ? 'green' : 'grey'
        ">
                                                <v-icon v-if="item.relatedOrganisation.coldstore" color="green"
                                                    class="mr-1">check</v-icon>
                                                Coldstore
                                            </v-chip>
                                            <v-chip outlined @click="
                                            (item.relatedOrganisation.consignee =
                                                !item.relatedOrganisation.consignee),
                                                relationshipKey++
                                                " :color="item.relatedOrganisation.consignee ? 'green' : 'grey'
        ">
                                                <v-icon v-if="item.relatedOrganisation.consignee" color="green"
                                                    class="mr-1">check</v-icon>
                                                Consignee
                                            </v-chip>
                                            <v-chip outlined @click="
                                            (item.relatedOrganisation.shipper =
                                                !item.relatedOrganisation.shipper),
                                                relationshipKey++
                                                " :color="item.relatedOrganisation.shipper ? 'green' : 'grey'
        ">
                                                <v-icon v-if="item.relatedOrganisation.shipper" color="green"
                                                    class="mr-1">check</v-icon>
                                                Shipper
                                            </v-chip>
                                            <v-chip outlined @click="
                                            (item.relatedOrganisation.forwarder =
                                                !item.relatedOrganisation.forwarder),
                                                relationshipKey++
                                                " :color="item.relatedOrganisation.forwarder ? 'green' : 'grey'
        ">
                                                <v-icon v-if="item.relatedOrganisation.forwarder" color="green"
                                                    class="mr-1">check</v-icon>
                                                Forwarder
                                            </v-chip>
                                            <v-chip outlined @click="
                                            (item.relatedOrganisation.notifyParty =
                                                !item.relatedOrganisation.notifyParty),
                                                relationshipKey++
                                                " :color="item.relatedOrganisation.notifyParty
        ? 'green'
        : 'grey'
        ">
                                                <v-icon v-if="item.relatedOrganisation.notifyParty" color="green"
                                                    class="mr-1">check</v-icon>
                                                Notify Party
                                            </v-chip>
                                            <v-chip outlined @click="
                                            (item.relatedOrganisation.buyer =
                                                !item.relatedOrganisation.buyer),
                                                relationshipKey++
                                                " :color="item.relatedOrganisation.buyer ? 'green' : 'grey'
        ">
                                                <v-icon v-if="item.relatedOrganisation.buyer" color="green"
                                                    class="mr-1">check</v-icon>
                                                Buyer
                                            </v-chip>
                                            <v-chip outlined @click="
                                            (item.relatedOrganisation.transporter =
                                                !item.relatedOrganisation.transporter),
                                                relationshipKey++
                                                " :color="item.relatedOrganisation.transporter
        ? 'green'
        : 'grey'
        ">
                                                <v-icon v-if="item.relatedOrganisation.transporter" color="green"
                                                    class="mr-1">check</v-icon>
                                                Transporter
                                            </v-chip>
                                            <v-chip outlined @click="
                                            (item.relatedOrganisation.shippingLine =
                                                !item.relatedOrganisation.shippingLine),
                                                relationshipKey++
                                                " :color="item.relatedOrganisation.shippingLine
        ? 'green'
        : 'grey'
        ">
                                                <v-icon v-if="item.relatedOrganisation.shippingLine" color="green"
                                                    class="mr-1">check</v-icon>
                                                Carrier
                                            </v-chip>
                                            <v-chip outlined
                                                @click="item.relatedOrganisation.pluginProvider = !item.relatedOrganisation.pluginProvider"
                                                :color="item.relatedOrganisation.pluginProvider ? 'green' : 'grey'">
                                                <v-icon v-if="item.relatedOrganisation.pluginProvider" color="green"
                                                    class="mr-1">check</v-icon>
                                                Plugin Provider
                                            </v-chip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-chip v-on="on" outlined
                                                        @click="item.relatedOrganisation.vasProvider = !item.relatedOrganisation.vasProvider"
                                                        :color="item.relatedOrganisation.vasProvider ? 'green' : 'grey'">
                                                        <v-icon v-if="item.relatedOrganisation.vasProvider" color="green"
                                                            class="mr-1">check</v-icon>
                                                        VAS Provider
                                                    </v-chip>
                                                </template>
                                                <span>Value Added Service Provider</span>
                                            </v-tooltip>
                                        </v-chip-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="text-center my-0 py-0" v-if="!hideHeader">
                                <v-row align="center">
                                    <v-col cols="12" class="text-left" sm="12" md="3">
                                        <h4>My Network Functions</h4>
                                    </v-col>
                                    <v-col cols="12" class="text-center" sm="12" md="9">
                                        <v-chip-group column multiple class="text-center">
                                            <v-chip outlined @click="item.inverse.producer = !item.inverse.producer"
                                                :color="item.inverse.producer ? 'green' : 'grey'">
                                                <v-icon v-if="item.inverse.producer" color="green"
                                                    class="mr-1">check</v-icon>
                                                Producer
                                            </v-chip>
                                            <v-chip outlined @click="
                                                item.inverse.coldstore = !item.inverse.coldstore
                                                " :color="item.inverse.coldstore ? 'green' : 'grey'">
                                                <v-icon v-if="item.inverse.coldstore" color="green"
                                                    class="mr-1">check</v-icon>
                                                Coldstore
                                            </v-chip>
                                            <v-chip outlined @click="
                                                item.inverse.consignee = !item.inverse.consignee
                                                " :color="item.inverse.consignee ? 'green' : 'grey'">
                                                <v-icon v-if="item.inverse.consignee" color="green"
                                                    class="mr-1">check</v-icon>
                                                Consignee
                                            </v-chip>
                                            <v-chip outlined @click="item.inverse.shipper = !item.inverse.shipper"
                                                :color="item.inverse.shipper ? 'green' : 'grey'">
                                                <v-icon v-if="item.inverse.shipper" color="green"
                                                    class="mr-1">check</v-icon>
                                                Shipper
                                            </v-chip>
                                            <v-chip outlined @click="
                                                item.inverse.forwarder = !item.inverse.forwarder
                                                " :color="item.inverse.forwarder ? 'green' : 'grey'">
                                                <v-icon v-if="item.inverse.forwarder" color="green"
                                                    class="mr-1">check</v-icon>
                                                Forwarder
                                            </v-chip>
                                            <v-chip outlined @click="
                                                item.inverse.notifyParty = !item.inverse.notifyParty
                                                " :color="item.inverse.notifyParty ? 'green' : 'grey'">
                                                <v-icon v-if="item.inverse.notifyParty" color="green"
                                                    class="mr-1">check</v-icon>
                                                Notify Party
                                            </v-chip>
                                            <v-chip outlined @click="item.inverse.buyer = !item.inverse.buyer"
                                                :color="item.inverse.buyer ? 'green' : 'grey'">
                                                <v-icon v-if="item.inverse.buyer" color="green" class="mr-1">check</v-icon>
                                                Buyer
                                            </v-chip>
                                            <v-chip outlined @click="item.inverse.transporter = !item.inverse.transporter"
                                                :color="item.inverse.transporter ? 'green' : 'grey'">
                                                <v-icon v-if="item.inverse.transporter" color="green"
                                                    class="mr-1">check</v-icon>
                                                Transporter
                                            </v-chip>
                                            <v-chip outlined @click="item.inverse.shippingLine = !item.inverse.shippingLine"
                                                :color="item.inverse.shippingLine ? 'green' : 'grey'">
                                                <v-icon v-if="item.inverse.shippingLine" color="green"
                                                    class="mr-1">check</v-icon>
                                                Carrier
                                            </v-chip>
                                            <v-chip outlined
                                                @click="item.inverse.pluginProvider = !item.inverse.pluginProvider"
                                                :color="item.inverse.pluginProvider ? 'green' : 'grey'">
                                                <v-icon v-if="item.inverse.pluginProvider" color="green"
                                                    class="mr-1">check</v-icon>
                                                Plugin Provider
                                            </v-chip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-chip v-on="on" outlined
                                                        @click="item.inverse.vasProvider = !item.inverse.vasProvider"
                                                        :color="item.inverse.vasProvider ? 'green' : 'grey'">
                                                        <v-icon v-if="item.inverse.vasProvider" color="green"
                                                            class="mr-1">check</v-icon>
                                                        VAS Provider
                                                    </v-chip>
                                                </template>
                                                <span>Value Added Service Provider</span>
                                            </v-tooltip>
                                        </v-chip-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" class="text-center" v-if="!item.organisationId ||
                                item.organisationId == $store.state.currentOrg.id
                                ">
                                <v-autocomplete outlined rounded label="Assigned Teams" item-text="name" item-value="id"
                                    multiple v-model="item.relationshipTeamIds" :items="teams" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" class="text-center" v-if="!item.organisationId ||
                                item.organisationId == $store.state.currentOrg.id
                                ">
                                <v-autocomplete label="Account Manager" :loading="loadingUsers" outlined rounded
                                    item-text="firstname" item-value="id" v-model="item.accountManager"
                                    :items="organisationUsers" return-object hide-no-data clearable
                                    :search-input.sync="searchOrganisationUser">
                                    <template v-slot:item="data" style="
                        border-top: 1px solid grey;
                        width: 100%;
                        padding: 10px;
                        margin: 0px;
                      ">
                                        <v-list-item-avatar v-if="data.item.avatar">
                                            <img :src="data.item.avatar" referrerpolicy="no-referrer" />
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ data.item.firstname }}
                                                {{ data.item.surname }}</v-list-item-title>

                                            <v-list-item-subtitle>
                                                <v-icon class="mr-2" small>email</v-icon>{{ data.item.emailAddress }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                <v-icon class="mr-2" small>call</v-icon>{{ data.item.mobileNumber }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>

                                    <template v-slot:selection="data">
                                        <v-avatar size="32" class="mr-1">
                                            <img v-if="data.item && data.item.avatar" :src="data.item.avatar"
                                                referrerpolicy="no-referrer" />
                                        </v-avatar>
                                        <span v-if="data.item">
                                            {{ data.item.firstname }} {{ data.item.surname }}
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <!-- <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  class="text-center"
                  v-if="
                    !item.organisationId ||
                    item.organisationId == $store.state.currentOrg.id
                  "
                >
                <v-text-field outlined rounded v-model="item.essCertId" label="essCert / COO ID"></v-text-field>
                </v-col> -->
                            <v-col cols="12" class="text-center">
                                <v-btn text @click="saveRelationship(item)" :loading="submitting"
                                    color="primary">Submit</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="organisationDialog" persistent width="750px" :fullscreen="$vuetify.breakpoint.xs">
            <v-card v-if="organisationItem">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title> Submit Request </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="(organisationDialog = false), (organisationItem = null)">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="6" class="text-center my-0 py-0">
                            <v-text-field label="Name*" v-model="organisationItem.name" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="text-center my-0 py-0">
                            <v-autocomplete hide-no-data hide-selected label="Country*" dense outlined :items="countries"
                                clearable item-text="name" item-value="name" :menu-props="{ closeOnContentClick: true }"
                                return-object v-model="organisationItem.country" :loading="loadingCountry">
                                <template v-slot:selection="data">
                                    <v-avatar size="32" class="mr-1" v-if="data.item">
                                        <v-img contain v-if="data.item.iso2"
                                            :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"></v-img>
                                    </v-avatar>
                                    <span v-if="data.item">
                                        {{ data.item.name }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <v-list-item-avatar>
                                        <v-img contain v-if="data.item.iso2"
                                            :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="text-center my-0 py-0">
                            <v-text-field label="Registration No." v-model="organisationItem.registrationNumber" outlined
                                dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="text-center my-0 py-0">
                            <v-text-field label="VAT No." v-model="organisationItem.vatNumber" outlined
                                dense></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" class="text-center my-0 py-0">
                            <v-text-field label="Email" v-model="organisationItem.emailAddress" outlined
                                dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="text-center my-0 py-0">
                            <v-text-field label="Telephone Number" v-model="organisationItem.telephoneNumber" outlined
                                dense>
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" class="text-center my-0 py-0">
                            <v-row align="center">
                                <v-col cols="12" class="text-left" sm="12" md="3">
                                    <h4>Organisation Network Functions</h4>
                                </v-col>
                                <v-col cols="12" class="text-center" sm="12" md="9">
                                    <v-chip-group column multiple class="text-center">
                                        <v-chip outlined @click="
                                            organisationItem.producer = !organisationItem.producer
                                            " :color="organisationItem.producer ? 'green' : 'grey'">
                                            <v-icon v-if="organisationItem.producer" color="green"
                                                class="mr-1">check</v-icon>
                                            Producer
                                        </v-chip>
                                        <v-chip outlined @click="
                                            organisationItem.coldstore = !organisationItem.coldstore
                                            " :color="organisationItem.coldstore ? 'green' : 'grey'">
                                            <v-icon v-if="organisationItem.coldstore" color="green"
                                                class="mr-1">check</v-icon>
                                            Coldstore
                                        </v-chip>
                                        <v-chip outlined @click="
                                            organisationItem.consignee = !organisationItem.consignee
                                            " :color="organisationItem.consignee ? 'green' : 'grey'">
                                            <v-icon v-if="organisationItem.consignee" color="green"
                                                class="mr-1">check</v-icon>
                                            Consignee
                                        </v-chip>
                                        <v-chip outlined @click="
                                            organisationItem.shipper = !organisationItem.shipper
                                            " :color="organisationItem.shipper ? 'green' : 'grey'">
                                            <v-icon v-if="organisationItem.shipper" color="green"
                                                class="mr-1">check</v-icon>
                                            Shipper
                                        </v-chip>
                                        <v-chip outlined @click="
                                            organisationItem.forwarder = !organisationItem.forwarder
                                            " :color="organisationItem.forwarder ? 'green' : 'grey'">
                                            <v-icon v-if="organisationItem.forwarder" color="green"
                                                class="mr-1">check</v-icon>
                                            Forwarder
                                        </v-chip>
                                        <v-chip outlined @click="
                                            organisationItem.notifyParty =
                                            !organisationItem.notifyParty
                                            " :color="organisationItem.notifyParty ? 'green' : 'grey'">
                                            <v-icon v-if="organisationItem.notifyParty" color="green"
                                                class="mr-1">check</v-icon>
                                            Notify Party
                                        </v-chip>
                                        <v-chip outlined @click="organisationItem.buyer = !organisationItem.buyer"
                                            :color="organisationItem.buyer ? 'green' : 'grey'">
                                            <v-icon v-if="organisationItem.buyer" color="green" class="mr-1">check</v-icon>
                                            Buyer
                                        </v-chip>
                                        <v-chip outlined @click="
                                            organisationItem.transporter =
                                            !organisationItem.transporter
                                            " :color="organisationItem.transporter ? 'green' : 'grey'">
                                            <v-icon v-if="organisationItem.transporter" color="green"
                                                class="mr-1">check</v-icon>
                                            Transporter
                                        </v-chip>
                                        <v-chip outlined @click="
                                            organisationItem.shippingLine =
                                            !organisationItem.shippingLine
                                            " :color="organisationItem.shippingLine ? 'green' : 'grey'">
                                            <v-icon v-if="organisationItem.shippingLine" color="green"
                                                class="mr-1">check</v-icon>
                                            Carrier
                                        </v-chip>
                                    </v-chip-group>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" sm="12" class="text-center my-0 py-0">
                            <v-list>
                                <v-subheader>Addresses
                                    <v-btn class="ml-2" icon color="primary" @click="addAddress()">
                                        <v-icon>add_circle_outline</v-icon>
                                    </v-btn>
                                </v-subheader>
                                <v-list-item v-for="(
                      address, index
                    ) in organisationItem.organisationAddresses" :key="index" @click="editAddress(address, index)">
                                    <v-list-item-action>
                                        <v-icon>location_on</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content class="text-left">
                                        <v-list-item-subtitle class="text-wrap">{{
                                            concatenateAddress(address)
                                        }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>

                        <v-col cols="12" sm="12" class="text-center my-0 py-0">
                            <v-list>
                                <v-subheader class="text-center">Users
                                    <v-btn class="ml-2" icon color="primary" @click="(userItem = {}), (userDialog = true)">
                                        <v-icon>add_circle_outline</v-icon>
                                    </v-btn>
                                </v-subheader>
                                <v-list-item v-for="(user, index) in organisationItem.organisationUsers" :key="index">
                                    <v-list-item-action>
                                        <v-icon>person</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content class="text-left">
                                        <v-list-item-title>
                                            {{ user.user.firstname }} {{ user.user.surname }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            {{ user.user.emailAddress }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle class="text-wrap">
                                            {{ user.user.position }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="red" @click="removeUser(index)">
                                            <v-icon>remove</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <v-btn color="primary" :loading="loadingNewOrganisation" text
                                @click="submitOrganisationRequest">Submit Request</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Dialog to manage Addresses -->
        <v-dialog v-model="addressDialog" persistent width="800" :fullscreen="$vuetify.breakpoint.xs">
            <AddressModalVue :item="addressItem" :loading="loadingAddress" :dialogVisible="addressDialog" :HQ="HQ"
                @close="closeAddressModal()" @save="saveAddress" transition="scale-transition" />
        </v-dialog>

        <v-dialog v-model="userDialog" persistent width="400px" :fullscreen="$vuetify.breakpoint.xs">
            <v-card v-if="userItem">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title> Manage User </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="(userDialog = false), (userItem = {})">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field outlined dense label="Name" v-model="userItem.firstname"></v-text-field>
                    <v-text-field outlined dense label="Surname" v-model="userItem.surname"></v-text-field>
                    <v-text-field outlined dense label="Company Position" v-model="userItem.position"></v-text-field>
                    <v-text-field outlined dense label="Email Address" v-model="userItem.emailAddress"></v-text-field>
                    <v-row justify="center">
                        <v-btn color="primary" text @click="addUser(userItem)">Done</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import AddressModalVue from "../SystemComponents/AddressModal.vue";
export default {
    components: {
        AddressModalVue,
    },
    props: ["item", "hideHeader"],
    data: () => ({
        addressDialog: false,
        addressItem: null,
        HQ: undefined,
        addressKey: 0,
        companyHeaders: [
            {
                text: "ID",
                value: "id",
                width: "10%",
                sortable: true,
                align: "left",
            },
            {
                text: "Name",
                value: "name",
                width: "25%",
                sortable: true,
                align: "left",
            },
            {
                text: "Address",
                value: "address",
                width: "65%",
                sortable: true,
                align: "left",
            },
        ],
        countries: [],
        isLoading: false,
        loadingCountry: false,
        loadingNewOrganisation: false,
        loadingUsers: false,
        loadingAddress: false,
        organisationDialog: false,
        organisationItem: null,
        organisationList: {
            total: 0,
            data: [],
        },
        organisationPage: 1,
        organisationParams: {
            limit: 10,
            offset: 0,
            search: null,
        },
        organisationUsers: [],
        refreshKey: 0,
        relationshipKey: 0,
        searchManager: false,
        searchOrganisation: null,
        searchOrganisationUser: null,
        selectedCountry: {
            // continent: "Africa",
            // id: 343,
            // iso2: "ZA",
            // name: "South Africa",
        },
        submitting: false,
        teams: [],
        timeout: null,
        userTimeout: null,
        userDialog: false,
        userItem: null,
    }),
    watch: {
        "item.accountManager": {
            immediate: true,
            handler(val) {
                if (val) {
                    let find = this.organisationUsers.find((x) => x.id == val.id);
                    if (!find) {
                        this.organisationUsers.push(val);
                    }
                }
            },
        },
        async organisationPage(value) {
            this.isLoading = true;
            this.organisationParams.offset =
                (value - 1) * this.organisationParams.limit;
            this.organisationParams.search = this.searchOrganisation;
            (this.organisationParams.country = this.selectedCountry.name),
                this.$API
                    .searchGlobalOrganisation({
                        params: this.organisationParams,
                    })
                    .then((res) => {
                        this.organisationList = res;
                    })
                    .finally(() => (this.isLoading = false));
            // await this.getRelations();
        },
        searchOrganisation(val) {
            this.organisationPage = 1;
            this.item.relatedOrganisation = {};
            if (this.isLoading) {
                clearTimeout(this.timeout);
            }
            this.isLoading = true;

            // delay new call 500ms
            this.timeout = setTimeout(() => {
                this.organisationParams.search = val;
                (this.organisationParams.country = this.selectedCountry.name),
                    this.$API
                        .searchGlobalOrganisation({
                            params: this.organisationParams,
                        })
                        .then((res) => {
                            this.organisationList = res;
                        })
                        .finally(() => (this.isLoading = false));
            }, 500);
        },
        searchOrganisationUser(val) {
            // Items have already been requested
            //   if (this.loadingUsers) return;
            if (this.loadingUsers) {
                clearTimeout(this.userTimeout);
            }

            this.loadingUsers = true;
            this.userTimeout = setTimeout(() => {
                this.$API
                    .searchOrganisationUser({
                        params: {
                            search: val,
                        },
                    })
                    .then((res) => {
                        this.organisationUsers = res;
                    })
                    .finally(() => (this.loadingUsers = false));
            }, 500);
            // Lazily load input items
        },
        "selectedCountry.name": {
            immediate: true,
            handler(val) {
                if (val) {
                    this.organisationPage = 1;
                    if (this.isLoading) {
                        clearTimeout(this.timeout);
                    }
                    this.isLoading = true;

                    // delay new call 500ms
                    this.timeout = setTimeout(() => {
                        (this.organisationParams.country = val),
                            this.$API
                                .searchGlobalOrganisation({
                                    params: this.organisationParams,
                                })
                                .then((res) => {
                                    this.organisationList = res;
                                })
                                .finally(() => (this.isLoading = false));
                    }, 500);
                }
            },
        },
    },
    async created() {
        this.getTeams();
        this.loadingCountry = true;
        this.countries = await this.$API.searchCountriesBasic({});
        this.loadHQ();
        this.loadingCountry = false;
    },
    methods: {
        addAddress() {
            this.addressItem = {
                organisationId:
                    this.item &&
                        this.item.relatedOrganisation &&
                        this.item.relatedOrganisation.id
                        ? this.item.relatedOrganisation.id
                        : this.organisationItem && this.organisationItem.id
                            ? this.organisationItem.id
                            : null,
                index:
                    this.item &&
                        this.item.relatedOrganisation &&
                        this.item.relatedOrganisation.organisationAddresses
                        ? this.item.relatedOrganisation.organisationAddresses.length
                        : this.organisationItem &&
                            this.organisationItem.organisationAddresses
                            ? this.organisationItem.organisationAddresses.length
                            : null,
                countryRegion:
                    this.item &&
                        this.item.relatedOrganisation &&
                        this.item.relatedOrganisation.id
                        ? this.item.relatedOrganisation.countryRegion
                        : this.organisationItem && this.organisationItem.id
                            ? this.organisationItem.countryRegion
                            : null,
            };
            if (this.addressItem.countryRegion) {
                this.addressItem.country = this.addressItem.countryRegion.name;
                this.addressItem.countryCode = this.addressItem.countryRegion.iso2;
            }

            this.addressItem.modalType = "Add";
            this.addressDialog = true;
        },
        saveAddress(address) {
            this.loadingAddress = true;
            if (this.item.relatedOrganisation && this.item.relatedOrganisation.id) {
                if (address.id) {
                    let index =
                        this.item.relatedOrganisation.organisationAddresses.findIndex(
                            (x) => x.id == address.id
                        );
                    if (index > -1) {
                        this.item.relatedOrganisation.organisationAddresses[index] =
                            address;
                    } else {
                        this.item.relatedOrganisation.organisationAddresses.push(address);
                    }
                } else {
                    let index =
                        this.item.relatedOrganisation.organisationAddresses.findIndex(
                            (x) => x.index == address.index
                        );
                    if (index > -1) {
                        this.item.relatedOrganisation.organisationAddresses[index] =
                            address;
                    } else {
                        this.item.relatedOrganisation.organisationAddresses.push(address);
                    }
                }
            } else {
                if (address.id) {
                    let index = this.organisationItem.organisationAddresses.findIndex(
                        (x) => x.id == address.id
                    );
                    if (index > -1) {
                        this.organisationItem.organisationAddresses[index] = address;
                    } else {
                        this.organisationItem.organisationAddresses.push(address);
                    }
                } else {
                    let index = this.organisationItem.organisationAddresses.findIndex(
                        (x) => x.index == address.index
                    );
                    if (index > -1) {
                        this.organisationItem.organisationAddresses[address.index] =
                            address;
                    } else {
                        this.organisationItem.organisationAddresses.push(address);
                    }
                }
            }
            this.loadHQ();
            this.addressDialog = false;
            this.loadingAddress = false;
            this.addressItem = {};
        },
        addressKeyPlus() {
            this.addressKey++;
        },
        closeAddressModal() {
            this.addressItem = {};
            this.addressDialog = false;
        },
        editAddress(address, index) {
            this.addressItem = address;
            this.addressItem.index = index;
            this.addressItem.modalType = "Edit";
            this.addressDialog = true;
        },
        async loadHQ() {
            this.HQ = undefined;
            if (
                this.item.relatedOrganisation &&
                this.item.relatedOrganisation.organisationAddresses &&
                this.item.relatedOrganisation.organisationAddresses.length > 0
            ) {
                this.HQ = this.item.relatedOrganisation.organisationAddresses.find(
                    (x) => x.isHQ
                );
            } else if (
                this.organisationItem &&
                this.organisationItem.organisationAddresses
            ) {
                this.HQ = this.organisationItem.organisationAddresses.find(
                    (x) => x.isHQ
                );
            }
        },
        addUser(user) {
            this.organisationItem.organisationUsers.push({ user: user });
            this.userDialog = false;
            this.userItem = {};
        },

        constructAddressLine(addressLine, address) {
            // switch (addressLine) {
            //     case 1:
            //         return address.addressLine1
            //     case 2:
            //         return address.town ?? address.addressLine2
            //     case 3:
            //         return [address.subdivision ?? address.addressLine3, address.country ?? address.addressLine4, address.postalCode].filter(Boolean).join(', ')
            // }
            switch (addressLine) {
                case 1:
                    return [address.addressLine1, address.addressLine2].filter(Boolean).join(', ')
                case 2:
                    return [address.addressLine3, address.addressLine4].filter(Boolean).join(', ')
                case 3:
                    return [address.town, address.subdivision, address.country, address.postalCode].filter(Boolean).join(', ')
            }
        },
        concatenateAddress(address) {

            let result = [
                this.constructAddressLine(1, address),
                this.constructAddressLine(2, address),
                this.constructAddressLine(3, address)
            ];
            // if (address.addressLine1) {
            //   result.push(address.addressLine1);
            // }
            // if (address.addressLine2) {
            //   result.push(address.addressLine2);
            // }
            // if (address.addressLine4) {
            //   result.push(address.addressLine3);
            // }
            // if (address.addressLine4) {
            //   result.push(address.addressLine4);
            // }
            // if (address.town) {
            //   result.push(address.town);
            // }
            // if (address.subdivision) {
            //   result.push(address.subdivision);
            // }
            // if (address.country) {
            //   result.push(address.country);
            // }
            // if (address.postalCode) {
            //   result.push(address.postalCode);
            // }
            return result.filter(Boolean).join(", ");
        },
        createOrganisation() {
            this.organisationItem = {
                name: null,
                country: this.selectedCountry,
                vatNumber: null,
                emailAddress: null,
                telephoneNumber: null,
                organisationAddresses: [],
                organisationUsers: [],
                producer: false,
                coldstore: false,
                consignee: false,
                buyer: false,
                forwarder: false,
                notifyParty: false,
                shipper: false,
                transporter: false,
                shippingLine: false,
            };
            this.organisationDialog = true;
        },
        countryChange() {
            this.$refs.countrySelect.blur();
            this.$refs.companySearch.focus();
        },
        async getTeams() {
            this.teams = await this.$API.getTeams();
        },
        removeUser(index) {
            this.organisationItem.organisationUsers.splice(index, 1);
        },
        async saveRelationship(item) {
            this.submitting = true;
            if (!item.isCustomer && !item.isSupplier) {
                item.isCustomer = true;
            }

            if (!item.id) {
                item.accountManagerId = item.accountManager
                    ? item.accountManager.id
                    : null;
                item.relatedOrganisationId = item.relatedOrganisation.id;
                item.inviteAccepted = true;
                let relation = await this.$API.createRelation(item);
                item.id = relation.id;

                if (item.relationshipTeamIds && item.relationshipTeamIds.length > 0) {
                    let orgTeams = item.relationshipTeamIds.map((x) => ({
                        teamId: x,
                        organisationRelationshipId: relation.id,
                    }));

                    if (orgTeams.length > 0) {
                        await this.$API.createRelationshipTeam({
                            batch: orgTeams,
                        });
                    }
                }
                this.$message.success("Successfully Created!");
            } else {
                item.accountManagerId = item.accountManager
                    ? item.accountManager.id
                    : null;
                await this.$API.updateRelation(item);
                this.$message.success("Successfully Updated!");
            }
            if (
                item.relatedOrganisatio &&
                item.relatedOrganisation.organisationAddresses &&
                item.relatedOrganisation.organisationAddresses.filter((x) => !x.id)
                    .length > 0
            ) {
                let newAddresses =
                    item.relatedOrganisation.organisationAddresses.filter((x) => !x.id);
                newAddresses.forEach((x) => {
                    x.organisationId = item.relatedOrganisation.id;
                });
                for (let i = 0; i < newAddresses.length; i++) {
                    let address = newAddresses[i];
                    let res = await this.$API.createOrganisationAddress(address);
                    address.id = res.id;
                }
            }
            if (
                (item.relationshipTeamIds && item.relationshipTeamIds.length > 0) ||
                (item.relationshipTeams && item.relationshipTeams.length > 0)
            ) {
                let create = item.relationshipTeamIds.filter(
                    (x) => !item.relationshipTeams.map((y) => y.teamId).includes(x)
                );
                let remove = item.relationshipTeams.filter(
                    (x) => !item.relationshipTeamIds.includes(x.teamId)
                );

                if (create.length > 0) {
                    await this.$API.createRelationshipTeam({
                        batch: create.map((x) => ({
                            teamId: x,
                            organisationRelationshipId: item.id,
                        })),
                    });
                } else {
                    for (let j = 0; j < remove.length; j++) {
                        let relationshipteamId = item.relationshipTeams.find(
                            (x) => x.teamId == remove[j].teamId
                        ).id;
                        await this.$API.updateRelationshipTeam({
                            id: relationshipteamId,
                            isActive: false,
                            isDeleted: true,
                        });
                    }
                }
            }
            this.searchManager = false;
            this.$emit("refresh", item);
            this.$emit("close");
            this.submitting = false;
        },
        selectCompany(row) {
            // console.log(row)
            this.$emit("selection", row)
            this.item.relatedOrganisation = row;
            this.refreshKey++;
            // console.log(row)
        },
        async submitOrganisationRequest() {
            this.organisationItem.isActive = true;
            try {
                this.loadingNewOrganisation = true;
                this.organisationItem.countryCode = this.organisationItem.country.iso2;
                this.organisationItem.country = this.organisationItem.country.name;

                // this.item.country = this.item.country.name

                let org = await this.$API.createOrganisation(this.organisationItem);
                this.item.relatedOrganisation = org;
                this.item.relatedOrganisationId = org.id;

                this.item.forwarder = org.forwarder;
                this.item.shipper = org.shipper;
                this.item.buyer = org.buyer;
                this.item.consignee = org.consignee;
                this.item.notifyParty = org.notifyParty;
                this.item.coldstore = org.coldstore;
                this.item.producer = org.producer;
                this.item.transporter = org.transporter;
                this.item.shippingLine = org.shippingLine;
                this.item.organisationAddresses = org.organisationAddresses;

                if (this.hideHeader) {
                    this.saveRelationship(this.item);
                }

                this.$message.success("Request successfully submitted!");

                this.organisationDialog = false;
                this.loadingNewOrganisation = false;
                this.organisationItem = {};
                // this.$emit("close");
                // this.item = {};
            } catch (e) {
                this.$message.error("Oops! Something went wrong");
            }
        },
    },
};
</script>
<style>
.v-dialog>.v-card>.v-toolbar {
    position: sticky;
    top: 0;
    z-index: 999;
}
</style>
  